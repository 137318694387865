<template lang="pug">
  base-page
    template(v-slot:titlu)
      span Grupuri drepturi
    template(v-slot:leftmenu)
      el-tooltip(content="Adauga un nou grup drepturi" v-if="$has_right('editare-grupuri-drepturi')")
        el-button(type='primary' @click='show_dialog()' icon='el-icon-plus' size='small' circle)
      
    el-table( :data="Results" )
      el-table-column( label="Nume" )
        template(slot-scope="prop")
          el-tag( v-if="prop.row.Sters == '1'" type='danger') {{prop.row.Name}}
          p(v-else) {{prop.row.Name}}
      el-table-column( fixed="right" label="Actiuni" width="200px" )
        template( slot-scope="scope" )
          el-tooltip( content="Modificare" v-if="$has_right('editare-grupuri-drepturi')")
            el-button( type="primary" size='mini' icon="el-icon-edit"  circle @click="show_dialog(scope.row.Id)" )
    grup-drepturi-dialog( ref='dlg' @save="refresh_info()" )
</template>

<script>
import settings from "../backend/LocalSettings";
import BasePage from "../pages/BasePage";
import GrupDrepturiDialog from '../dialogs/GrupDrepturiDialog';
import TitluPagina from '../widgets/TitluPagina';

export default {
  name: "grupuri-drepturi",
  extends: BasePage,
  components: {
      'base-page': BasePage,
      'grup-drepturi-dialog':GrupDrepturiDialog,
      'titlu-pagina': TitluPagina
  },
  data () {
    return { 
      Results: [],
      Filters: {},
    }
  },
  methods: {
    async get_info() {
      this.refresh_info();
    },
    async refresh_info() {
      var response = await this.post("list-grupuri-drepturi", { Filters: this.Filters } );
      this.Results = response.Results;
      //
      this.select_menu_item('grupuri-drepturi');
    },
    show_dialog(id) {
      this.$refs.dlg.show_me(id);
    },
    async delete_item( item ) {
      var confirm =  await this.$confirm(`Sterg ${item.Name} ?`, 'Warning');
      if ( confirm ) {
        await this.post("delete-grup-drepturi", { id: item.Id } );
        this.refresh_info();
      }
    }
  },
  mounted() {
    settings.verify_login_and_redirect(this);
    this.get_info();
  }
};
</script>

<style lang="less" scoped>
</style>
