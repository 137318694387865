<template lang="pug">
    el-dialog( title="Grup drepturi" :visible.sync="showPopup" class="my-dialog-class" )
        el-form( label-position="top" :inline="false" :rules="rules" label-width="100%"  :model='selectedObject'  ref='my-form' @submit.prevent="save" v-loading="loadingVisible" )
            el-row( :gutter="15" )
                el-col( :span="24" )
                    el-form-item( label="Nume" prop="Name"  :required="true" )
                        el-input( v-model="selectedObject.Name" )
                el-col.grid-item(:span="12" v-for="c in CategoriiDrepturi" :key="'categorie' + c.Id")
                    el-card.box-card
                        .clearfix(slot="header")
                            strong {{c.Name}}
                        .text.item(v-for="d in c.Drepturi" :key="'drept' + d.Id")
                            el-checkbox(v-model="d.Bifat") {{d.Name}}
        span( slot="footer" class="dialog-footer" )
            el-button( @click="showPopup=false" ) Renunta
            el-button( type="primary" @click="save") Salveaza
</template>

<script>
import settings from "@/backend/LocalSettings";
import BaseComponent from '@/pages/BaseComponent';

export default {
  name: "grup-drepturi-dialog",
  extends: BaseComponent,
  data () {
    return { 
      showPopup: false,
      mode: 'add',
      selectedObject: { Name: "" },
      CategoriiDrepturi: [],
      rules: {
          Name: [ { required: true, message: "Numele este obligatoriu" } ],
      }
    }
  },
  methods: {
    async show_me( id ) {
      this.showPopup        = true;
      this.CategoriiDrepturi.forEach(c => {
        c.Drepturi.forEach(d => d.Bifat = false)
      })
      if( id == null ) 
      {
        this.mode                = "add";
        this.selectedObject.Name = "";
      }
      else
      {
        this.mode             = "edit";
        var            result = await this.post("get-grup-drepturi", { id: id } );
        this.selectedObject   = result.Item;
        result.Bifate.forEach(id => {
          this.CategoriiDrepturi.forEach(c => {
            const d = c.Drepturi.find(d => d.Id == id)
            if (d) {
              d.Bifat = true
            }
          })
        })
      }
    },
    async get_info() {
      var result = await this.post("Grupuri_drepturi/get_info");
      this.CategoriiDrepturi = result.CategoriiDrepturi;
    },
    async save() {
      this.$refs['my-form'].validate( async (valid) => {
        if (valid) {
          const drepturi = this.CategoriiDrepturi
            .reduce((res, c) => [...res, ...c.Drepturi], [])
            .filter(d => d.Bifat)
            .map(d => d.Id);
          await this.post("save-grup-drepturi", { mode: this.mode, object: this.selectedObject, drepturi} );
          this.showPopup = false;
          this.$emit("save");
        }
      });
    }
  },
  mounted() {
    this.get_info();
  }
};
</script>

<style lang="less" scoped>
.full-width {
    width: 100%;
}
.grid-item{
    padding-top: 10px;
    min-height: 200px;
    .el-card{
        height:100%;
    }
}
</style>